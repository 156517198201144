import type { QueryBuilderParams } from '@nuxt/content'

export const path = '/articles/repost'

export const usedKeys = ['_id', '_path', 'title', 'image', 'category', 'date', 'schedule']

export const getWhereCondition = (): any => {
  return [
    {
      schedule: {
        $or: [
          { $exists: false },
          {
            $and: [{ $exists: true }, { $lt: new Date() }],
          },
        ],
      },
    },
  ]
}

export const getQueryParams = (skip?: number, limit?: number): QueryBuilderParams => {
  return {
    path,
    where: getWhereCondition(),
    skip,
    limit,
    sort: [{ _id: -1, $numeric: true }],
  }
}
